$teal: #3dccc7;
$burgundy: #8b0435;

// Star parallax background animation
// n is number of stars required
@function multiple-box-shadow($n) {
	$value: '#{random(2560)}px #{random(4200)}px #FFF';
	@for $i from 2 through $n {
		$value: '#{$value} , #{random(2560)}px #{random(4200)}px #FFF';
	}

	@return unquote($value);
}
$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

// html {
// 	height: 100%;
// 	overflow: hidden;
// }

html {
	background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
}
#stars {
	width: 1px;
	height: 1px;
	background: transparent;
	box-shadow: $shadows-small;
	animation: animStar 50s linear infinite;

	&:after {
		overflow: hidden;
		content: ' ';
		position: absolute;
		top: 4200px;
		width: 1px;
		height: 1px;
		background: transparent;
		box-shadow: $shadows-small;
	}
}
#stars2 {
	width: 2px;
	height: 2px;
	background: transparent;
	box-shadow: $shadows-medium;
	animation: animStar 100s linear infinite;

	&:after {
		overflow: hidden;
		content: ' ';
		position: absolute;
		top: 4200px;
		width: 2px;
		height: 2px;
		background: transparent;
		box-shadow: $shadows-medium;
	}
}
#stars3 {
	width: 3px;
	height: 3px;
	background: transparent;
	box-shadow: $shadows-big;
	animation: animStar 150s linear infinite;

	&:after {
		overflow: hidden;
		content: ' ';
		position: absolute;
		top: 4200px;
		width: 3px;
		height: 3px;
		background: transparent;
		box-shadow: $shadows-big;
	}
}
@keyframes animStar {
	from {
		transform: translateY(0px);
	}
	to {
		transform: translateY(-4200px);
	}
}

// Floating animation
.float {
	animation: float 4s ease-in-out infinite;
	-webkit-backface-visibility: hidden;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

// TODO: remove if not going to use
// Color fading animation
.fade {
	animation: fade 6s ease-in-out infinite;
}
@keyframes fade {
	0% {
		color: $teal;
	}
	50% {
		color: $burgundy;
	}
	100% {
		color: $teal;
	}
}

.orb {
	z-index: -1;
}
