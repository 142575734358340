@tailwind base;
@tailwind components;
@tailwind utilities;

/*--- Fonts ---*/
@import './variables/fonts.scss';
@import 'animation.scss';

/*--- Custom non-tailwind css ---*/
@import './custom.scss';

/*--- Style clear-fix ---*/
body,
html {
	margin: 0;
	padding: 0;
	width: 100%;
	overflow-x: hidden;
}

body {
	height: 100%;
	overflow: hidden;
}

#root {
	overflow: hidden;
	min-height: 100%;
}

h1 {
	margin: unset;
}
