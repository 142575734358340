.btn {
	border-color: transparent;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex-shrink: 0;
	text-align: center;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-radius: 0.5rem;
	line-height: 2;
	padding-left: 1rem;
	padding-right: 1rem;
	min-height: 3rem;
	font-weight: 600;
	font-size: 0.8rem;
	text-transform: uppercase;
	text-transform: var(--btn-text-case, uppercase);
	border-width: var(--border-btn, 1px);
	-webkit-animation: button-pop var(--animation-btn, 0.25s) ease-out;
	animation: button-pop var(--animation-btn, 0.25s) ease-out;
}

.spacer {
	aspect-ratio: 900/600;
	width: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}